<template>
  <van-popup
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-overlay="false"
    :style="{
      width: '84%',
    }"
    round
  >
    <div class="share-pop u-text-center">
      <h4>请确认您的所在地</h4>
      <p class="u-text-left tips">
        精准的贷款申请城市将决定我们是否能为您提供<span>快速贷款服务</span>，请按您所在地如实填写。
      </p>
      <p class="u-text-left">您的定位城市：</p>
      <van-button
        class="comfirm-btn"
        plain
        block
        color="#3563FA"
        round
        @click="handleClose"
        :loading="!address ? true : false"
        loading-text="获取位置中..."
      >
        {{ address && address !== "定位失败" ? `确定：${address}` : address }}
      </van-button>
      <van-button
        plain
        block
        color="#868E9E"
        round
        @click="handleManualSelection"
      >
        手动选择所在地
      </van-button>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "AddressPop",
  data() {
    return {
      showPop: this.$attrs.value, // 弹窗显示状态
      address: this.$attrs.address, // 地址
    };
  },
  watch: {
    "$attrs.address"(val) {
      this.address = val;
    },
  },
  methods: {
    // 监听弹窗关闭
    handleClose() {
      this.showPop = false;
      this.$emit("change", this.showPop, 0);
    },

    // 监听手动选择按钮点击
    handleManualSelection() {
      this.showPop = false;
      this.$emit("change", this.showPop, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.share-pop {
  padding: 0.48rem 0.4rem;

  > h4 {
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: bold;
    color: #111c31;
    margin-bottom: 0.24rem;
  }

  .tips {
    margin-bottom: 0.4rem;
  }

  > p {
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: normal;
    color: #868e9e;
    text-align: justify;

    span {
      color: #f03d23;
    }
  }

  .comfirm-btn {
    margin: 0.24rem 0;
  }
}
</style>
